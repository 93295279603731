import React, { Component } from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { css } from "@emotion/core"
import SEO from "../../components/seo"
import Header from "../../components/header/header"
import {
  VideoPortfolio,
  VideoPortfolioLeftTitle,
  VideoPortfolioLeft,
  VideoPortfolioRight,
  VideoPortfolioItemContent,
  VideoPortfolioItem,
  ProgressBar,
  VideoReveal,
} from "../../styled-components/videography-styles"
import { FloatingText } from "../../styled-components/floating-text"

class VideoPortfolioPage extends Component {
  deskScrollH() {
    document.querySelector(".video-portfolio").addEventListener(
      "wheel",
      e => {
        e.deltaY > 0
          ? this.deltaRight(Math.abs(e.deltaY))
          : this.deltaLeft(Math.abs(e.deltaY))
      },
      { passive: true }
    )
  }

  deltaRight(delta) {
    const portRight = document.querySelector(".video-portfolio__right")
    portRight.scrollLeft += delta
    this.progressBar((portRight.scrollLeft += delta))
  }

  deltaLeft(delta) {
    const portRight = document.querySelector(".video-portfolio__right")
    portRight.scrollLeft -= delta
    this.progressBar((portRight.scrollLeft -= delta))
  }

  progressBar(scrollDist) {
    const progBar = document.querySelector(".progress-bar")
    const portRightDist =
      document.querySelector(".video-portfolio__right").scrollWidth -
      document.querySelector(".video-portfolio__right").clientWidth
    const progress = Math.round((scrollDist * 100.0) / portRightDist)
    if (progress >= 2 || progress <= 100) {
      progBar.style.width = `${progress}%`
    }
  }

  openReveal(e) {
    const src = e.target.parentNode.parentNode.parentNode.dataset.embedScript
    const reveal = document.querySelector(".reveal")
    const iframe = document.createElement("div")
    iframe.classList.add("iframe-wrapper")
    iframe.style.maxWidth = "1440px"
    iframe.innerHTML = src
    reveal.classList.add("open")
    reveal.appendChild(iframe)
  }

  closeReveal() {
    const reveal = document.querySelector(".reveal")
    const iframe = reveal.querySelector(".iframe-wrapper")
    reveal.removeChild(iframe)
    reveal.classList.remove("open")
  }

  componentDidMount() {
    if (window.matchMedia("(min-width: 1024px)").matches) {
      this.deskScrollH()
      Object.assign(document.querySelector("body").style, {
        overflow: "hidden",
      })

      Object.assign(document.querySelector(".video-portfolio").style, {
        height: `${window.innerHeight}px`,
      })
    }
  }

  componentWillUnmount() {
    document.querySelector("body").style.overflow = "auto"
  }

  render() {
    const play = this.props.data.allFile.edges[0].node.childImageSharp
    const sanity = this.props.data.allSanityPortfolio
    const items = []
    for (let i = 0; i < sanity.edges[0].node.portItem.length; i++) {
      items.push(
        <VideoPortfolioItem
          onClick={this.openReveal}
          key={i}
          data-embed-script={sanity.edges[0].node.portItem[
            i
          ].embedScript.replace(
            `<script src="https://player.vimeo.com/api/player.js"></script>`,
            ""
          )}
        >
          <Img
            className="vid__img-hover"
            fluid={sanity.edges[0].node.portItem[i].hovImage.asset.fluid}
          />
          <Img
            className="vid__img"
            fluid={sanity.edges[0].node.portItem[i].defImage.asset.fluid}
          />
          <VideoPortfolioItemContent>
            <p>{sanity.edges[0].node.portItem[i].hovText}</p>
            <div className="spacer"></div>
          </VideoPortfolioItemContent>
          <Img className="vid__play" fixed={play.fixed} />
        </VideoPortfolioItem>
      )
    }
    return (
      <>
        <SEO title="Video" />
        <Header />
        <VideoReveal className="reveal">
          <button onClick={this.closeReveal}>&times;</button>
        </VideoReveal>
        <FloatingText>We can help</FloatingText>
        <VideoPortfolio className="video-portfolio">
          <VideoPortfolioLeft>
            <VideoPortfolioLeftTitle>
              Web<span>Video</span>
            </VideoPortfolioLeftTitle>
            <p>{sanity.edges[0].node.description}</p>
            <Link
              css={css`
                text-decoration: none;
                color: #2497c6;
                text-transform: uppercase;
                letter-spacing: 4px;
                margin-right: 2rem;
                font-weight: 700;
                font-size: 0.875rem;
                position: relative;
                transition: all 0.4s ease-in-out;

                &:hover {
                  color: #fefefe;
                }

                &::before {
                  position: absolute;
                  top: 50%;
                  right: calc(100% + 1rem);
                  display: block;
                  content: "";
                  width: 2rem;
                  height: 1px;
                  background-color: #fff;
                }
              `}
              to="/portfolio"
            >
              Back to portfolio
            </Link>
          </VideoPortfolioLeft>
          <VideoPortfolioRight className="video-portfolio__right">
            {items}
          </VideoPortfolioRight>
          <ProgressBar className="progress-bar" />
        </VideoPortfolio>
      </>
    )
  }
}

export const query = graphql`
  {
    allFile(filter: { relativePath: { eq: "play-button.png" } }) {
      edges {
        node {
          childImageSharp {
            fixed(width: 100, quality: 100) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    }
    allSanityPortfolio(filter: { portPage: { eq: "Videography" } }) {
      edges {
        node {
          description
          portItem {
            ... on SanityVideoItem {
              hovText
              defImage {
                asset {
                  fluid(maxWidth: 672) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              hovImage {
                asset {
                  fluid(maxWidth: 672) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              embedScript
            }
          }
        }
      }
    }
  }
`

export default VideoPortfolioPage
