import styled from "@emotion/styled"
import { PortfolioWrapperRel } from "./portfolio-styles"

export const VideoPortfolio = styled(PortfolioWrapperRel)`
  @media screen and (min-width: 1024px) {
    padding-left: 4rem;
    flex-direction: row;
  }
`

export const VideoPortfolioLeftTitle = styled.p`
  font-family: "Montserrat", sans-serif;
  transform: scale(1, 0.9);
  font-weight: 700;
  text-transform: lowercase;
  text-decoration: none;
  line-height: 0.85;
  color: #fefefe;
  font-size: 4.5rem;
  position: relative;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    font-size: 6rem;
  }

  span {
    color: #fefefe;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-color: #fefefe;
    -webkit-text-stroke-width: 1px;
    position: relative;
    right: 1.5rem;
    bottom: 1rem;
  }
`

export const VideoPortfolioLeft = styled.div`
  color: #fefefe;
  text-align: right;
  z-index: 2;

  @media screen and (min-width: 1024px) {
    min-width: 450px;
    width: 40%;
    padding-left: 4rem;
  }

  p {
    font-family: "Montserrat", sans-serif;
    transform: scale(1, 0.9);
    max-width: 50%;
    margin-right: 2rem;
    margin-left: auto;

    :last-of-type {
      line-height: 1.8;
    }

    @media (min-width: 1024px) {
      max-width: 75%;
    }
  }
`

export const VideoPortfolioRight = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 4rem;
  overflow: hidden;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }

  @media screen and (min-width: 1024px) {
    margin-top: 0;
    margin-right: 2rem;
    flex-direction: row;
    overflow-x: scroll;
    overflow-y: hidden;
    scrollbar-width: none;

    ::-webkit-scrollbar {
      display: none;
    }
  }
`

export const VideoPortfolioItemContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  opacity: 0;
  transition: all 0.4s ease-in-out;
  z-index: 2;

  @media (min-width: 1024px) {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  p {
    text-align: center;
    font-size: 1.5rem;
    font-family: "Montserrat", sans-serif;
    transform: scale(1, 0.9);
    font-weight: 700;
    text-transform: lowercase;
    color: #fefefe;
    max-width: 85vw;

    @media (min-width: 1024px) {
      white-space: nowrap;
    }
  }

  .spacer {
    width: 1px;
    height: 6rem;
    background-color: #fefefe;
    display: block;
    margin-bottom: 3rem;

    @media (min-width: 1024px) {
      height: 10rem;
      margin-bottom: 2rem;
    }
  }
`

export const VideoPortfolioItem = styled.div`
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 4rem;
  min-width: 90vw;
  min-height: 640px;

  @media screen and (min-width: 1024px) {
    min-width: 640px;
    min-height: 480px;
    margin: 8rem 2rem;
  }

  .vid__play {
    z-index: 2;

    @media (max-width: 1023px) {
      bottom: 50%;
      transform: translateY(-50%);
    }
  }

  :hover {
    cursor: pointer;
    z-index: 2;

    .vid__img {
      opacity: 0;
      transform: scale(1.05);
    }

    .vid__img-hover {
      transform: scale(1.05);
    }

    ${VideoPortfolioItemContent} {
      opacity: 1;
      top: 0;

      @media (min-width: 1024px) {
        transform: translate(-50%, -33%);
      }
    }
  }

  .vid__img,
  .vid__img-hover {
    filter: brightness(0.67);
    width: 100%;
    height: 100%;
    max-width: 90vw;
    transition: all 0.4s ease-in-out;
    position: absolute !important;

    @media (min-width: 1024px) {
      left: 0;
      top: 0;
      max-width: 640px;
      max-height: 480px;
    }
  }
`

export const ProgressBar = styled.div`
  width: 1%;
  height: 8px;
  background-color: #2497c6;
  position: fixed;
  left: 0;
  bottom: 0;
  transition: all 1s ease-in-out;

  @media screen and (max-width: 1023px) {
    display: none;
  }
`

export const VideoReveal = styled.div`
  position: fixed;
  box-sizing: border-box;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  padding: 4rem;
  background: #000c;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
  transition: all 0.4s ease-in-out;
  opacity: 0;
  pointer-events: none;

  &.open {
    opacity: 1;
    pointer-events: all;
  }

  div {
    width: 100%;
  }

  button {
    position: absolute;
    right: 2rem;
    top: 2rem;
    background: none;
    border: none;
    line-height: 1ch;
    font-size: 2rem;
    transform: rotate(45deg);
    transition: all 0.4s ease-in-out;
    color: #fefefe;

    :hover {
      cursor: pointer;
      transform: rotate(0);
    }
  }
`
